export const PATH_PARTS = {
  tools: 'tools',
  playerReplace: 'player-replace',
  playerActivate: 'player-activate',
  ptvPlayerActivate: 'player-activate-ptv',
  playerTemplates: 'player-templates',
  playerState: 'player-state',
  playerScreenshot: 'screenshot',
  playerList: 'player-list',
  playerEdit: 'player-edit',
  playerCreate: 'player-create',
  playerMovieLogs: 'player-movie-logs',
  beacon: 'beacon',
  incidentProviderList: 'incident-provider-list',
  incidentProviderEdit: 'incident-provider-edit',
  incidentProviderCreate: 'incident-provider-create',
  incidentTemplateList: 'incident-template-list',
  incidentTemplateEdit: 'incident-template-edit',
  incidentTemplateCreate: 'incident-template-create',
  zoneDetailList: 'zone-detail-list',
  zoneDetailEdit: 'zone-detail-edit',
  zoneEdit: 'edit-zone',
  zoneEditLocation: 'move-zone',
  zoneGeoEdit: 'edit-geo-zone',
  zonesImport: 'zones-import',
  zoneImpressions: 'zone-impressions',
  zonesMatch: 'zones-match',
  zoneTags: 'zone-tags',
  editZoneFeed: 'edit-zone-feed',
  zoneList: 'zone-list',
  zonePics: 'zone-pics',
  jiraMap: 'jira-map',
  screenEdit: 'screen-edit',
  screenList: 'screen-list',
  inventoryActivate: 'inventory-activate',
  inventoryEdit: 'inventory-edit',
  inventoryReplace: 'inventory-replace',
  inventoryTemplates: 'inventory-templates',
  inventorySlotsEdit: 'inventory-slots-edit',
  inventoryList: 'inventory-list',
  inventoryMovieLogs: 'inventory-movie-logs',
  tagList: 'tag-list',
  tagEdit: 'tag-edit',
  tagCreate: 'tag-create',
  tagTypeList: 'tag-type-list',
  tagTypeEdit: 'tag-type-edit',
  tagTypeCreate: 'tag-type-create',
  vpnClients: 'vpn-clients',
  programmatic: 'programmatic',
  program: 'program',
  loopJobs: 'loop-jobs',
  dbDump: 'db-dump',
  forbidden: 'forbidden',
  faq: 'faq',
  about: 'about',
};

export const ROUTES = {
  dashboard: '/',
  playerReplace: `/${PATH_PARTS.tools}/${PATH_PARTS.playerReplace}`,
  playerActivate: `/${PATH_PARTS.tools}/${PATH_PARTS.playerActivate}`,
  ptvPlayerActivate: `/${PATH_PARTS.tools}/${PATH_PARTS.ptvPlayerActivate}`,
  playerTemplates: `/${PATH_PARTS.tools}/${PATH_PARTS.playerTemplates}`,
  playerState: `/${PATH_PARTS.tools}/${PATH_PARTS.playerState}`,
  playerScreenshot: `/${PATH_PARTS.tools}/${PATH_PARTS.playerScreenshot}`,
  playerList: `/${PATH_PARTS.tools}/${PATH_PARTS.playerList}`,
  playerEdit: `/${PATH_PARTS.tools}/${PATH_PARTS.playerEdit}`,
  playerCreate: `/${PATH_PARTS.tools}/${PATH_PARTS.playerCreate}`,
  playerMovieLogs: `/${PATH_PARTS.tools}/${PATH_PARTS.playerMovieLogs}`,
  beacon: `/${PATH_PARTS.tools}/${PATH_PARTS.beacon}`,
  zoneEdit: `/${PATH_PARTS.tools}/${PATH_PARTS.zoneEdit}`,
  zoneEditLocation: `/${PATH_PARTS.tools}/${PATH_PARTS.zoneEditLocation}`,
  incidentTemplateEdit: `/${PATH_PARTS.tools}/${PATH_PARTS.incidentTemplateEdit}`,
  incidentTemplateCreate: `/${PATH_PARTS.tools}/${PATH_PARTS.incidentTemplateCreate}`,
  incidentTemplateList: `/${PATH_PARTS.tools}/${PATH_PARTS.incidentTemplateList}`,
  incidentProviderList: `/${PATH_PARTS.tools}/${PATH_PARTS.incidentProviderList}`,
  incidentProviderEdit: `/${PATH_PARTS.tools}/${PATH_PARTS.incidentProviderEdit}`,
  incidentProviderCreate: `/${PATH_PARTS.tools}/${PATH_PARTS.incidentProviderCreate}`,
  zoneDetailList: `/${PATH_PARTS.tools}/${PATH_PARTS.zoneDetailList}`,
  zoneDetailEdit: `/${PATH_PARTS.tools}/${PATH_PARTS.zoneDetailEdit}`,
  zonesImport: `/${PATH_PARTS.tools}/${PATH_PARTS.zonesImport}`,
  zoneImpressions: `/${PATH_PARTS.tools}/${PATH_PARTS.zoneImpressions}`,
  zonesMatch: `/${PATH_PARTS.tools}/${PATH_PARTS.zonesMatch}`,
  zoneTags: `/${PATH_PARTS.tools}/${PATH_PARTS.zoneTags}`,
  editZoneFeed: `/${PATH_PARTS.tools}/${PATH_PARTS.editZoneFeed}`,
  zoneList: `/${PATH_PARTS.tools}/${PATH_PARTS.zoneList}`,
  zoneEditGeoZone: `/${PATH_PARTS.tools}/${PATH_PARTS.zoneGeoEdit}`,
  zonePics: `/${PATH_PARTS.tools}/${PATH_PARTS.zonePics}`,
  jiraMap: `/${PATH_PARTS.tools}/${PATH_PARTS.jiraMap}`,
  screenList: `/${PATH_PARTS.tools}/${PATH_PARTS.screenList}`,
  screenEdit: `/${PATH_PARTS.tools}/${PATH_PARTS.screenEdit}`,
  inventoryActivate: `/${PATH_PARTS.tools}/${PATH_PARTS.inventoryActivate}`,
  inventoryTemplates: `/${PATH_PARTS.tools}/${PATH_PARTS.inventoryTemplates}`,
  inventoryEdit: `/${PATH_PARTS.tools}/${PATH_PARTS.inventoryEdit}`,
  inventorySlotsEdit: `/${PATH_PARTS.tools}/${PATH_PARTS.inventorySlotsEdit}`,
  inventoryList: `/${PATH_PARTS.tools}/${PATH_PARTS.inventoryList}`,
  inventoryReplace: `/${PATH_PARTS.tools}/${PATH_PARTS.inventoryReplace}`,
  inventoryMovieLogs: `/${PATH_PARTS.tools}/${PATH_PARTS.inventoryMovieLogs}`,
  tagList: `/${PATH_PARTS.tools}/${PATH_PARTS.tagList}`,
  tagEdit: `/${PATH_PARTS.tools}/${PATH_PARTS.tagEdit}`,
  tagCreate: `/${PATH_PARTS.tools}/${PATH_PARTS.tagCreate}`,
  tagTypeList: `/${PATH_PARTS.tools}/${PATH_PARTS.tagTypeList}`,
  tagTypeEdit: `/${PATH_PARTS.tools}/${PATH_PARTS.tagTypeEdit}`,
  tagTypeCreate: `/${PATH_PARTS.tools}/${PATH_PARTS.tagTypeCreate}`,
  loopJobs: `/${PATH_PARTS.tools}/${PATH_PARTS.loopJobs}`,
  vpnClients: `/${PATH_PARTS.tools}/${PATH_PARTS.vpnClients}`,
  programmatic: `/${PATH_PARTS.tools}/${PATH_PARTS.programmatic}`,
  program: `/${PATH_PARTS.tools}/${PATH_PARTS.program}`,
  dbDump: `/${PATH_PARTS.tools}/${PATH_PARTS.dbDump}`,
  forbidden: `/${PATH_PARTS.forbidden}`,
  about: `/${PATH_PARTS.about}`,
  faqTools: `/${PATH_PARTS.faq}/${PATH_PARTS.tools}`,
};
