import React, { memo } from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';

import SnackbarManager from 'components/common/Snackbar';
import ConfirmDialog from 'components/common/ConfirmDialog';
import { ModeAtom } from 'atoms/mode';
import { createThemeByMode } from 'common/theme';

function Providers({ children }) {
  const mode = useRecoilValue(ModeAtom);
  const theme = createThemeByMode(mode);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
        <SnackbarManager autoHideDuration={7000} />
        <ConfirmDialog />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

Providers.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
};

export default memo(Providers);
