import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material';

import Navbar from 'components/common/Navbar';

const Content = styled('div')(({ theme }) => ({
  marginTop: theme.navbarHeight,
  height: '100%',
  paddingTop: 10,
}));

/**
 * Empty layout. Renders navbar and children
 * @returns {JSX.Element}
 */
function EmptyLayout({ children }) {
  return (
    <div>
      <Navbar />
      <Content>{children}</Content>
    </div>
  );
}

EmptyLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.node,
  ]).isRequired,
};

export default memo(EmptyLayout);
