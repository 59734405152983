import React, { useRef, memo } from 'react';
import PropTypes from 'prop-types';
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import CloseIcon from '@mui/icons-material/CloseRounded';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';

import { useToggle } from 'hooks/useToggle';

// eslint-disable-next-line react/prop-types
function UserDataRow({ label, value }) {
  return (
    <Box p="3px" display="flex">
      <Box fontWeight={600} width="80px">
        {label}:
      </Box>
      &nbsp;
      <Box maxWidth={250} style={{ wordBreak: 'break-word' }}>
        {value}
      </Box>
    </Box>
  );
}

function UserPopper({ user, logout }) {
  const anchorRef = useRef(null);
  const [open, toggle] = useToggle(false);
  const name = [user.firstName ?? '', user.lastName ?? ''].join(' ');

  return (
    <Box>
      <Box
        style={{ cursor: 'pointer' }}
        lineHeight={1}
        height={28}
        fontSize={28}
        onClick={toggle}
        ref={anchorRef}
      >
        <PersonOutlineRoundedIcon fontSize="inherit" />
      </Box>
      <Popper
        id="user-popper"
        style={{ zIndex: 3 }}
        open={open}
        anchorEl={anchorRef.current}
      >
        <Box
          mt="18px"
          mr="14px"
          p="12px"
          pt="20px"
          borderRadius="8px"
          border={1}
          borderColor="rgba(255,255,255,.3)"
          bgcolor="lavender.l700"
          position="relative"
        >
          <Box
            onClick={toggle}
            style={{ cursor: 'pointer' }}
            position="absolute"
            right="4px"
            top="4px"
          >
            <CloseIcon fontSize="small" />
          </Box>
          <UserDataRow label="Name" value={name} />
          <Divider />
          <UserDataRow label="Email" value={user.email || '-'} />
          <Divider />
          <UserDataRow label="Groups" value={user.groups.join(', ')} />
          <Box mb="10px" />
          <Button fullWidth variant="outlined" onClick={logout}>
            Logout
          </Button>
        </Box>
      </Popper>
    </Box>
  );
}

UserPopper.propTypes = {
  logout: PropTypes.func,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    groups: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default memo(UserPopper);
