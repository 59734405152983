import AutorenewRounded from '@mui/icons-material/AutorenewRounded';
import AddToQueueRoundedIcon from '@mui/icons-material/AddToQueueRounded';
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import SettingsRemoteRoundedIcon from '@mui/icons-material/SettingsRemoteRounded';
import AspectRatioRoundedIcon from '@mui/icons-material/AspectRatioRounded';
import WarningIcon from '@mui/icons-material/Warning';
import MapRoundedIcon from '@mui/icons-material/MapRounded';
import RoomRoundedIcon from '@mui/icons-material/RoomRounded';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
import ViewListRoundedIcon from '@mui/icons-material/ViewListRounded';
import EqualizerRoundedIcon from '@mui/icons-material/EqualizerRounded';
import DirectionsBusRoundedIcon from '@mui/icons-material/DirectionsBusRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import StorageRoundedIcon from '@mui/icons-material/StorageRounded';
import TableChartRoundedIcon from '@mui/icons-material/TableChartRounded';
import PublishRoundedIcon from '@mui/icons-material/PublishRounded';
import EmojiEmotionsRoundedIcon from '@mui/icons-material/EmojiEmotionsRounded';
import CompareArrowsRoundedIcon from '@mui/icons-material/CompareArrowsRounded';
import LocalOfferRoundedIcon from '@mui/icons-material/LocalOfferRounded';
import ImageRoundedIcon from '@mui/icons-material/ImageRounded';
import Forward10RoundedIcon from '@mui/icons-material/Forward10Rounded';
import BookmarksRoundedIcon from '@mui/icons-material/BookmarksRounded';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutlineRounded';
import GpsFixedRoundedIcon from '@mui/icons-material/GpsFixedRounded';
import OndemandVideoRoundedIcon from '@mui/icons-material/OndemandVideoRounded';
import TvRoundedIcon from '@mui/icons-material/TvRounded';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import { ROUTES } from 'constants/routes';
import {
  DEV_GROUP,
  FIELD_FORCE_GROUP,
  SALES_ADMIN_GROUP,
  SALES_COORDINATE_GROUP,
  SALES_GROUP,
  SUPPORT_GROUP,
  PARAMETRIC_GROUP,
  PROJECTS_GROUP,
} from 'constants/user';

const LS_USER_GROUPS = [
  FIELD_FORCE_GROUP,
  SUPPORT_GROUP,
  SALES_GROUP,
  SALES_COORDINATE_GROUP,
  SALES_ADMIN_GROUP,
  DEV_GROUP,
  PARAMETRIC_GROUP,
  PROJECTS_GROUP,
];
const LS_TECH_GROUPS = [FIELD_FORCE_GROUP, SUPPORT_GROUP, DEV_GROUP];
const LS_IT_GROUPS = [SUPPORT_GROUP, DEV_GROUP];

export const TOOLS_LINKS = {
  playerList: {
    to: ROUTES.playerList,
    Icon: ViewListRoundedIcon,
    text: 'Player list',
    allowedGroups: LS_IT_GROUPS,
  },
  playerState: {
    to: ROUTES.playerState,
    Icon: EqualizerRoundedIcon,
    text: 'Player state',
    allowedGroups: [...LS_TECH_GROUPS, PARAMETRIC_GROUP, PROJECTS_GROUP],
  },
  playerReplace: {
    to: ROUTES.playerReplace,
    Icon: AutorenewRounded,
    text: 'Replace player',
    allowedGroups: LS_TECH_GROUPS,
  },
  playerActivate: {
    to: ROUTES.playerActivate,
    Icon: AddToQueueRoundedIcon,
    text: 'Activate player',
    allowedGroups: LS_TECH_GROUPS,
  },
  ptvPlayerActivate: {
    to: ROUTES.ptvPlayerActivate,
    Icon: DirectionsBusRoundedIcon,
    text: 'Activate PTV',
    allowedGroups: LS_TECH_GROUPS,
  },
  inventoryList: {
    to: ROUTES.inventoryList,
    Icon: ViewListRoundedIcon,
    text: 'F12D List',
    allowedGroups: [...LS_TECH_GROUPS, PARAMETRIC_GROUP, PROJECTS_GROUP],
  },
  inventoryReplace: {
    to: ROUTES.inventoryReplace,
    Icon: AutorenewRounded,
    text: 'Replace F12D',
    allowedGroups: LS_TECH_GROUPS,
  },
  inventoryActivate: {
    to: ROUTES.inventoryActivate,
    Icon: AddToQueueRoundedIcon,
    text: 'Activate F12D',
    allowedGroups: LS_TECH_GROUPS,
  },
  beacon: {
    to: ROUTES.beacon,
    Icon: SettingsRemoteRoundedIcon,
    text: 'Bind beacon',
    allowedGroups: LS_TECH_GROUPS,
  },
  jiraMap: {
    to: ROUTES.jiraMap,
    Icon: RoomRoundedIcon,
    text: 'Jira map',
    allowedGroups: [...LS_TECH_GROUPS, PROJECTS_GROUP],
  },
  playerTemplates: {
    to: ROUTES.playerTemplates,
    Icon: ListAltRoundedIcon,
    text: 'Player template',
    allowedGroups: LS_IT_GROUPS,
  },
  playerMovieLogs: {
    to: ROUTES.playerMovieLogs,
    Icon: StorageRoundedIcon,
    hidden: true,
    text: 'Movie logs',
    allowedGroups: LS_TECH_GROUPS,
  },
  playerScreenshot: {
    to: ROUTES.playerScreenshot,
    Icon: AspectRatioRoundedIcon,
    text: 'Screenshot',
    hidden: true,
    allowedGroups: LS_TECH_GROUPS,
  },
  playerEdit: {
    to: ROUTES.playerEdit,
    Icon: EditRoundedIcon,
    text: 'Player edit',
    hidden: true,
    allowedGroups: LS_IT_GROUPS,
  },
  playerCreate: {
    to: ROUTES.playerCreate,
    Icon: EditRoundedIcon,
    text: 'Player create',
    hidden: true,
    allowedGroups: LS_IT_GROUPS,
  },
  faqTools: {
    to: ROUTES.faqTools,
    Icon: HelpOutlineRoundedIcon,
    text: 'FAQ',
    hidden: true,
    allowedGroups: LS_USER_GROUPS,
  },
  zoneList: {
    to: ROUTES.zoneList,
    Icon: ViewListRoundedIcon,
    text: 'Zone list',
    allowedGroups: LS_IT_GROUPS,
  },
  zoneDetailList: {
    to: ROUTES.zoneDetailList,
    Icon: TableChartRoundedIcon,
    text: 'Zone details',
    allowedGroups: [
      ...LS_IT_GROUPS,
      SALES_ADMIN_GROUP,
      PROJECTS_GROUP,
      SALES_COORDINATE_GROUP,
    ],
  },
  zoneDetailEdit: {
    to: ROUTES.zoneDetailEdit,
    Icon: EditRoundedIcon,
    text: 'Zones details edit',
    hidden: true,
    allowedGroups: [...LS_IT_GROUPS, SALES_ADMIN_GROUP, PROJECTS_GROUP],
  },
  zoneEdit: {
    to: ROUTES.zoneEdit,
    Icon: MapRoundedIcon,
    text: 'Edit zone',
    hidden: true,
    allowedGroups: LS_IT_GROUPS,
  },
  zoneGeoEdit: {
    to: ROUTES.zoneEditGeoZone,
    Icon: MapRoundedIcon,
    text: 'Edit geo zone',
    allowedGroups: LS_IT_GROUPS,
  },
  zoneEditLocation: {
    to: ROUTES.zoneEditLocation,
    Icon: MapRoundedIcon,
    text: 'Move zone',
    allowedGroups: [...LS_TECH_GROUPS, SALES_ADMIN_GROUP, PROJECTS_GROUP],
  },
  zoneWemfMatch: {
    to: ROUTES.zonesMatch,
    Icon: CompareArrowsRoundedIcon,
    text: 'Zones Match',
    allowedGroups: [DEV_GROUP, SALES_ADMIN_GROUP],
  },
  zonesImport: {
    to: ROUTES.zonesImport,
    Icon: PublishRoundedIcon,
    text: 'Zones import',
    allowedGroups: LS_IT_GROUPS,
  },
  zoneImpressions: {
    to: ROUTES.zoneImpressions,
    Icon: EmojiEmotionsRoundedIcon,
    text: 'Zone Impressions',
    allowedGroups: [DEV_GROUP, SALES_ADMIN_GROUP],
    hidden: true,
  },
  zoneTags: {
    to: ROUTES.zoneTags,
    Icon: LocalOfferRoundedIcon,
    text: 'Zone Tags',
    allowedGroups: [
      ...LS_IT_GROUPS,
      SALES_ADMIN_GROUP,
      PROJECTS_GROUP,
      SALES_COORDINATE_GROUP,
    ],
    hidden: true,
  },
  editZoneFeed: {
    to: ROUTES.editZoneFeed,
    Icon: LocalOfferRoundedIcon,
    text: 'Edit feed',
    allowedGroups: LS_IT_GROUPS,
    hidden: true,
  },
  zonePics: {
    to: ROUTES.zonePics,
    Icon: ImageRoundedIcon,
    text: 'Zone Pics',
    allowedGroups: [...LS_IT_GROUPS, SALES_ADMIN_GROUP, PROJECTS_GROUP],
    hidden: true,
  },
  tagList: {
    to: ROUTES.tagList,
    Icon: BookmarksRoundedIcon,
    text: 'Tag List',
    allowedGroups: [...LS_IT_GROUPS, SALES_ADMIN_GROUP, PROJECTS_GROUP],
  },
  tagEdit: {
    to: ROUTES.tagEdit,
    Icon: EditRoundedIcon,
    text: 'Tag Edit',
    allowedGroups: [...LS_IT_GROUPS, SALES_ADMIN_GROUP, PROJECTS_GROUP],
    hidden: true,
  },
  tagCreate: {
    to: ROUTES.tagCreate,
    Icon: AddCircleOutlineIcon,
    text: 'Tag Create',
    allowedGroups: [...LS_IT_GROUPS, SALES_ADMIN_GROUP, PROJECTS_GROUP],
    hidden: true,
  },
  tagTypeList: {
    to: ROUTES.tagTypeList,
    Icon: LocalOfferRoundedIcon,
    text: 'Tag Types',
    allowedGroups: [...LS_IT_GROUPS, SALES_ADMIN_GROUP, PROJECTS_GROUP],
  },
  tagTypeEdit: {
    to: ROUTES.tagTypeEdit,
    Icon: EditRoundedIcon,
    text: 'Tag type Edit',
    allowedGroups: [...LS_IT_GROUPS, SALES_ADMIN_GROUP, PROJECTS_GROUP],
    hidden: true,
  },
  tagTypeCreate: {
    to: ROUTES.tagTypeCreate,
    Icon: AddCircleOutlineIcon,
    text: 'Tag type Create',
    allowedGroups: [...LS_IT_GROUPS, SALES_ADMIN_GROUP, PROJECTS_GROUP],
    hidden: true,
  },
  incidentProviderList: {
    to: ROUTES.incidentProviderList,
    Icon: WarningIcon,
    text: 'Incident\nProviders',
    allowedGroups: LS_IT_GROUPS,
  },
  incidentProviderCreate: {
    to: ROUTES.incidentProviderCreate,
    Icon: WarningIcon,
    hidden: true,
    text: 'Incident\nProviders\nCreate',
    allowedGroups: LS_IT_GROUPS,
  },
  incidentProviderEdit: {
    to: ROUTES.incidentProviderEdit,
    Icon: WarningIcon,
    hidden: true,
    text: 'Incident\nProviders\nEdit',
    allowedGroups: LS_IT_GROUPS,
  },
  incidentTemplateList: {
    to: ROUTES.incidentTemplateList,
    Icon: BookmarksRoundedIcon,
    text: 'Incident\nTemplates',
    allowedGroups: LS_IT_GROUPS,
  },
  incidentTemplateEdit: {
    to: ROUTES.incidentTemplateEdit,
    Icon: EditRoundedIcon,
    text: 'Incident Template Edit',
    allowedGroups: LS_IT_GROUPS,
    hidden: true,
  },
  incidentTemplateCreate: {
    to: ROUTES.incidentTemplateCreate,
    Icon: AddCircleOutlineIcon,
    text: 'Incident Template Create',
    allowedGroups: LS_IT_GROUPS,
    hidden: true,
  },
  vpnClients: {
    to: ROUTES.vpnClients,
    Icon: LanguageRoundedIcon,
    text: 'Vpn clients',
    allowedGroups: LS_IT_GROUPS,
  },
  loopJobs: {
    to: ROUTES.loopJobs,
    Icon: Forward10RoundedIcon,
    text: 'Loop jobs',
    allowedGroups: [DEV_GROUP],
  },
  screenEdit: {
    to: ROUTES.screenEdit,
    Icon: EditRoundedIcon,
    text: 'Screen edit',
    allowedGroups: LS_TECH_GROUPS,
    hidden: true,
  },
  screenList: {
    to: ROUTES.screenList,
    Icon: TvRoundedIcon,
    text: 'Screen list',
    allowedGroups: LS_IT_GROUPS,
  },
  inventoryEdit: {
    to: ROUTES.inventoryEdit,
    Icon: EditRoundedIcon,
    text: 'Inventory edit',
    allowedGroups: [...LS_IT_GROUPS, PROJECTS_GROUP],
    hidden: true,
  },
  inventorySlotsEdit: {
    to: ROUTES.inventorySlotsEdit,
    Icon: EditRoundedIcon,
    text: 'Inventory slots edit',
    allowedGroups: [...LS_IT_GROUPS, PROJECTS_GROUP, SALES_ADMIN_GROUP],
    hidden: true,
  },
  inventoryMovieLogs: {
    to: ROUTES.inventoryMovieLogs,
    Icon: StorageRoundedIcon,
    text: 'Inventory movie logs',
    allowedGroups: [...LS_TECH_GROUPS, PARAMETRIC_GROUP, PROJECTS_GROUP],
    hidden: true,
  },
  inventoryTemplates: {
    to: ROUTES.inventoryTemplates,
    Icon: ListAltRoundedIcon,
    text: 'Inventory\ntemplate',
    allowedGroups: LS_IT_GROUPS,
  },
  programmatic: {
    to: ROUTES.programmatic,
    Icon: GpsFixedRoundedIcon,
    text: 'programmatic',
    allowedGroups: [DEV_GROUP, SALES_ADMIN_GROUP],
  },
  dbDump: {
    to: ROUTES.dbDump,
    Icon: CloudDownloadIcon,
    text: 'DB dump',
    allowedGroups: [DEV_GROUP],
  },
  program: {
    to: ROUTES.program,
    Icon: OndemandVideoRoundedIcon,
    text: 'Program',
    allowedGroups: [
      DEV_GROUP,
      SUPPORT_GROUP,
      SALES_ADMIN_GROUP,
      PROJECTS_GROUP,
    ],
  },
};

export const TOOLS_CATEGORIES = {
  all: {
    title: 'All',
    id: 'all',
    items: Object.values(TOOLS_LINKS),
  },
  player: {
    title: 'Player',
    id: 'player',
    items: [
      TOOLS_LINKS.playerList,
      TOOLS_LINKS.playerState,
      TOOLS_LINKS.playerReplace,
      TOOLS_LINKS.playerActivate,
      TOOLS_LINKS.ptvPlayerActivate,
      TOOLS_LINKS.screenList,
      TOOLS_LINKS.beacon,
      TOOLS_LINKS.jiraMap,
      TOOLS_LINKS.playerTemplates,
      TOOLS_LINKS.program,
    ],
  },
  inventory: {
    title: 'F12D',
    id: 'inventory',
    items: [
      TOOLS_LINKS.inventoryList,
      TOOLS_LINKS.inventoryActivate,
      TOOLS_LINKS.inventoryReplace,
      TOOLS_LINKS.inventoryTemplates,
      TOOLS_LINKS.beacon,
      TOOLS_LINKS.jiraMap,
    ],
  },
  zonesAndAds: {
    title: 'Zones and Ads',
    id: 'zones',
    items: [
      TOOLS_LINKS.zoneList,
      TOOLS_LINKS.editZoneFeed,
      TOOLS_LINKS.zoneDetailList,
      TOOLS_LINKS.zoneEdit,
      TOOLS_LINKS.zoneGeoEdit,
      TOOLS_LINKS.zoneEditLocation,
      TOOLS_LINKS.zoneWemfMatch,
      TOOLS_LINKS.zonesImport,
      TOOLS_LINKS.tagList,
      TOOLS_LINKS.tagTypeList,
    ],
  },
  content: {
    id: 'content',
    title: 'Content',
    items: [
      TOOLS_LINKS.programmatic,
      TOOLS_LINKS.incidentTemplateList,
      TOOLS_LINKS.incidentProviderList,
    ],
  },
  development: {
    title: 'Development',
    id: 'development',
    items: [TOOLS_LINKS.vpnClients, TOOLS_LINKS.loopJobs, TOOLS_LINKS.dbDump],
  },
  hidden: {
    title: 'Hidden',
    id: 'hidden',
    items: Object.values(TOOLS_LINKS).filter(val => !!val.hidden),
  },
};
