import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import { ReactComponent as Beer } from 'assets/images/beer.svg';

class ErrorBoundary extends React.Component {
  // eslint-disable-next-line react/state-in-constructor
  state = { error: false };

  static getDerivedStateFromError() {
    return { error: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    const { state, props } = this;
    if (state.error) {
      return (
        <Box textAlign="center" pt="100px" color="laveneder.l100">
          <h1>Something bad happened...</h1>
          <h2>Please don&apos;t panic. Call support and take a beer ;)</h2>
          <br />
          <br />
          <br />
          <Beer height="300" />
        </Box>
      );
    }
    return props.children;
  }
}

ErrorBoundary.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
};

export default memo(ErrorBoundary);
