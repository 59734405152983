const JWT_TOKEN_KEY = 'jwtToken';

/**
 * Class to encapsulate working with storage logic, singleton
 * Is used as an abstraction to work with storage implementation (local storage, RAM, etc)
 */
class Storage {
  set(key, value) {
    localStorage.setItem(key, value);
  }

  get(key) {
    return localStorage.getItem(key);
  }

  remove(key) {
    localStorage.removeItem(key);
  }

  setJwt(jwt) {
    return this.set(JWT_TOKEN_KEY, jwt);
  }

  getJwt() {
    return this.get(JWT_TOKEN_KEY) || '';
  }
}

export const storage = new Storage();
