import { useState, useCallback } from 'react';

/**
 * hook for toggle something. Returns toggle function and isActive state
 * @param {Boolean} defaultValue
 * @returns [boolean, (function(): void)]
 */
export const useToggle = (defaultValue = false) => {
  const [isActive, setIsActive] = useState(defaultValue);
  const toggle = useCallback(
    () => setIsActive(prevIsActive => !prevIsActive),
    [setIsActive],
  );

  return [isActive, toggle];
};
