export function EMPTY_FUNCTION() {
  return null;
}

export const EMPTY_ARRAY = [];
export const EMPTY_OBJECT = {};

export const STATUS_SUCCESS = 'STATUS_SUCCESS';
export const STATUS_WARNING = 'STATUS_WARNING';
export const STATUS_ERROR = 'STATUS_ERROR';
export const STATUS_UNKNOWN = 'STATUS_UNKNOWN';

export const STATUS_LOADING = 'STATUS_LOADING';
export const STATUS_INITIAL = 'STATUS_INITIAL';

export const SORT = {
  no: 'no',
  asc: 'ASC',
  desc: 'DESC',
};

export const TMP_ID_PREFIX = 'tmp';

export const NEW_ID = -1;

export const SORT_CHARS = {
  [SORT.no]: '',
  [SORT.asc]: '\u25b2',
  [SORT.desc]: '\u25bc',
};
