import React, { memo, lazy, Suspense } from 'react';
import { Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import CommonLayout from 'components/layouts/CommonLayout';
import { ROUTES } from 'constants/routes';
import { TOOLS_LINKS as TOOLS } from 'constants/tools';
import EmptyLayout from 'components/layouts/EmptyLayout';
import {
  FIELD_FORCE_GROUP,
  SALES_COORDINATE_GROUP,
  SALES_GROUP,
  SUPPORT_GROUP,
  DEV_GROUP,
  SALES_ADMIN_GROUP,
  PARAMETRIC_GROUP,
  PROJECTS_GROUP,
} from 'constants/user';
import { isMemberOf } from 'utils/user';
import DotsLoader from 'components/common/DotsLoader';

const Dashboard = lazy(() => import('components/pages/Dashboard'));
const Forbidden = lazy(() => import('components/pages/ForbiddenFallback'));
const NotFound = lazy(() => import('components/pages/NotFound'));
const PlayerReplace = lazy(() => import('components/tools/PlayerReplace'));
const InventoryReplace = lazy(
  () => import('components/tools/InventoryReplace'),
);
const PlayerActivate = lazy(() => import('components/tools/PlayerActivate'));
const PtvPlayerActivate = lazy(
  () => import('components/tools/PtvPlayerActivate'),
);
const PlayerTemplates = lazy(() => import('components/tools/PlayerTemplates'));
const InventoryTemplates = lazy(
  () => import('components/tools/InventoryTemplates'),
);
const PlayerState = lazy(() => import('components/tools/PlayerState'));
const PlayerList = lazy(() => import('components/tools/PlayerList'));
const PlayerEdit = lazy(() => import('components/tools/PlayerEdit'));
const PlayerCreate = lazy(() => import('components/tools/PlayerCreate'));
const PlayerMovieLogs = lazy(() => import('components/tools/PlayerMovieLogs'));
const PlayerScreenshot = lazy(
  () => import('components/tools/PlayerScreenshot'),
);
const BeaconSearch = lazy(() => import('components/tools/BeaconSearch'));
const EditZoneLocation = lazy(
  () => import('components/tools/EditZoneLocation'),
);
const IncidentProviderList = lazy(
  () => import('components/tools/IncidentProviderList'),
);
const IncidentProviderCreate = lazy(
  () => import('components/tools/IncidentProviderCreate'),
);
const IncidentProviderEdit = lazy(
  () => import('components/tools/IncidentProviderEdit'),
);
const IncidentTemplateList = lazy(
  () => import('components/tools/IncidentTemplateList'),
);
const IncidentTemplateEdit = lazy(
  () => import('components/tools/IncidentTemplateEdit'),
);
const IncidentTemplateCreate = lazy(
  () => import('components/tools/IncidentTemplateCreate'),
);
const ZoneList = lazy(() => import('components/tools/ZoneList'));
const ZoneDetailList = lazy(() => import('components/tools/ZoneDetailList'));
const ZoneDetailEdit = lazy(() => import('components/tools/ZoneDetailEdit'));
const ZoneEdit = lazy(() => import('components/tools/ZoneEdit'));
const ZonesImport = lazy(() => import('components/tools/ZonesImport'));
const ZoneImpressions = lazy(() => import('components/tools/ZonesImpressions'));
const ZoneGeoEdit = lazy(() => import('components/tools/ZoneGeoEdit'));
const ZonesWemfMatch = lazy(() => import('components/tools/ZonesWemfMatch'));
const ZoneTags = lazy(() => import('components/tools/ZoneTags'));
const ZonePics = lazy(() => import('components/tools/ZonePics'));
const ScreenList = lazy(() => import('components/tools/ScreenList'));
const ScreenEdit = lazy(() => import('components/tools/ScreenEdit'));
const InventoryList = lazy(() => import('components/tools/InventoryList'));
const InventoryEdit = lazy(() => import('components/tools/InventoryEdit'));
const InventorySlotsEdit = lazy(
  () => import('components/tools/InventorySlotsEdit'),
);
const InventoryActivate = lazy(
  () => import('components/tools/InventoryActivate'),
);
const InventoryMovieLogs = lazy(
  () => import('components/tools/InventoryMovieLogs'),
);
const TagList = lazy(() => import('components/tools/TagList'));
const TagEdit = lazy(() => import('components/tools/TagEdit'));
const TagCreate = lazy(() => import('components/tools/TagCreate'));
const TagTypeList = lazy(() => import('components/tools/TagTypeList'));
const TagTypeEdit = lazy(() => import('components/tools/TagTypeEdit'));
const TagTypeCreate = lazy(() => import('components/tools/TagTypeCreate'));

const LoopJobs = lazy(() => import('components/tools/LoopJobs'));
const JiraMap = lazy(() => import('components/tools/JiraMap'));
const VpnClients = lazy(() => import('components/tools/VpnClients'));
const FaqTools = lazy(() => import('components/faq/FaqTools'));
const Programmatic = lazy(() => import('components/tools/Programmatic'));
const Program = lazy(() => import('components/tools/Program'));
const AssignFeedToZone = lazy(
  () => import('components/tools/AssignFeedToZone'),
);
const DbDump = lazy(() => import('components/tools/DbDump'));

const LS_USER_GROUPS = [
  FIELD_FORCE_GROUP,
  SUPPORT_GROUP,
  SALES_GROUP,
  SALES_ADMIN_GROUP,
  SALES_COORDINATE_GROUP,
  DEV_GROUP,
  PARAMETRIC_GROUP,
  PROJECTS_GROUP,
];

/**
 * Routes Component. Manages all application routes
 * takes current user prop to check its rights
 */
function Routes({ user }) {
  return (
    <Suspense
      fallback={
        <EmptyLayout>
          <DotsLoader backdrop />
        </EmptyLayout>
      }
    >
      <Switch>
        <CommonLayout
          exact
          path={ROUTES.dashboard}
          component={Dashboard}
          hasAccess={isMemberOf(user, LS_USER_GROUPS)}
        />
        <CommonLayout
          exact
          path={ROUTES.playerReplace}
          component={PlayerReplace}
          hasAccess={isMemberOf(user, TOOLS.playerReplace.allowedGroups)}
          toolLink={TOOLS.playerReplace}
        />
        <CommonLayout
          exact
          path={ROUTES.playerActivate}
          component={PlayerActivate}
          hasAccess={isMemberOf(user, TOOLS.playerActivate.allowedGroups)}
          toolLink={TOOLS.playerActivate}
        />
        <CommonLayout
          exact
          path={ROUTES.ptvPlayerActivate}
          component={PtvPlayerActivate}
          toolLink={TOOLS.ptvPlayerActivate}
          hasAccess={isMemberOf(user, TOOLS.ptvPlayerActivate.allowedGroups)}
        />
        <CommonLayout
          exact
          path={ROUTES.playerTemplates}
          component={PlayerTemplates}
          hasAccess={isMemberOf(user, TOOLS.playerTemplates.allowedGroups)}
          toolLink={TOOLS.playerTemplates}
        />
        <CommonLayout
          exact
          fullWidth
          path={ROUTES.playerList}
          component={PlayerList}
          hasAccess={isMemberOf(user, TOOLS.playerList.allowedGroups)}
          toolLink={TOOLS.playerList}
        />
        <CommonLayout
          exact
          path={`${ROUTES.playerState}/:id?`}
          component={PlayerState}
          hasAccess={isMemberOf(user, TOOLS.playerState.allowedGroups)}
          toolLink={TOOLS.playerState}
        />
        <CommonLayout
          exact
          fullWidth
          path={`${ROUTES.playerScreenshot}/:id/:screenMac`}
          component={PlayerScreenshot}
          hasAccess={isMemberOf(user, TOOLS.playerScreenshot.allowedGroups)}
          toolLink={TOOLS.playerScreenshot}
        />
        <CommonLayout
          exact
          path={`${ROUTES.playerEdit}/:id`}
          component={PlayerEdit}
          hasAccess={isMemberOf(user, TOOLS.playerEdit.allowedGroups)}
          toolLink={TOOLS.playerEdit}
        />
        <CommonLayout
          exact
          path={ROUTES.playerCreate}
          component={PlayerCreate}
          hasAccess={isMemberOf(user, TOOLS.playerCreate.allowedGroups)}
          toolLink={TOOLS.playerCreate}
        />
        <CommonLayout
          exact
          fullWidth
          path={`${ROUTES.playerMovieLogs}/:id`}
          component={PlayerMovieLogs}
          hasAccess={isMemberOf(user, TOOLS.playerMovieLogs.allowedGroups)}
          toolLink={TOOLS.playerMovieLogs}
        />
        <CommonLayout
          exact
          path={`${ROUTES.beacon}`}
          component={BeaconSearch}
          hasAccess={isMemberOf(user, TOOLS.beacon.allowedGroups)}
          toolLink={TOOLS.beacon}
        />
        <CommonLayout
          exact
          path={`${ROUTES.zoneEditLocation}/:id?`}
          component={EditZoneLocation}
          hasAccess={isMemberOf(user, TOOLS.zoneEditLocation.allowedGroups)}
          toolLink={TOOLS.zoneEditLocation}
        />
        <CommonLayout
          exact
          fullWidth
          path={`${ROUTES.zoneDetailList}`}
          component={ZoneDetailList}
          hasAccess={isMemberOf(user, TOOLS.zoneDetailList.allowedGroups)}
          toolLink={TOOLS.zoneDetailList}
        />
        <CommonLayout
          exact
          fullWidth
          path={`${ROUTES.zoneList}`}
          component={ZoneList}
          hasAccess={isMemberOf(user, TOOLS.zoneList.allowedGroups)}
          toolLink={TOOLS.zoneList}
        />
        <CommonLayout
          exact
          path={`${ROUTES.zoneEdit}/:id`}
          component={ZoneEdit}
          hasAccess={isMemberOf(user, TOOLS.zoneEdit.allowedGroups)}
          toolLink={TOOLS.zoneEdit}
        />
        <CommonLayout
          exact
          path={`${ROUTES.editZoneFeed}/:zoneId`}
          component={AssignFeedToZone}
          hasAccess={isMemberOf(user, TOOLS.editZoneFeed.allowedGroups)}
          toolLink={TOOLS.editZoneFeed}
        />
        <CommonLayout
          exact
          path={ROUTES.zoneEditGeoZone}
          component={ZoneGeoEdit}
          hasAccess={isMemberOf(user, TOOLS.zoneGeoEdit.allowedGroups)}
          toolLink={TOOLS.zoneGeoEdit}
        />
        <CommonLayout
          exact
          path={`${ROUTES.zoneDetailEdit}/:id`}
          component={ZoneDetailEdit}
          hasAccess={isMemberOf(user, TOOLS.zoneDetailEdit.allowedGroups)}
          toolLink={TOOLS.zoneDetailEdit}
        />
        <CommonLayout
          exact
          path={ROUTES.zonesImport}
          component={ZonesImport}
          hasAccess={isMemberOf(user, TOOLS.zonesImport.allowedGroups)}
          toolLink={TOOLS.zonesImport}
        />
        <CommonLayout
          exact
          path={`${ROUTES.zoneImpressions}/:zoneId`}
          component={ZoneImpressions}
          hasAccess={isMemberOf(user, TOOLS.zonesImport.allowedGroups)}
          toolLink={TOOLS.zoneImpressions}
        />
        <CommonLayout
          exact
          path={ROUTES.zonesMatch}
          component={ZonesWemfMatch}
          hasAccess={isMemberOf(user, TOOLS.zoneWemfMatch.allowedGroups)}
          toolLink={TOOLS.zoneWemfMatch}
        />
        <CommonLayout
          exact
          path={`${ROUTES.zoneTags}/:zoneId`}
          component={ZoneTags}
          hasAccess={isMemberOf(user, TOOLS.zoneTags.allowedGroups)}
          toolLink={TOOLS.zoneTags}
        />
        <CommonLayout
          exact
          path={`${ROUTES.zonePics}/:zoneId`}
          component={ZonePics}
          hasAccess={isMemberOf(user, TOOLS.zonePics.allowedGroups)}
          toolLink={TOOLS.zonePics}
        />
        <CommonLayout
          exact
          fullWidth
          path={ROUTES.screenList}
          component={ScreenList}
          hasAccess={isMemberOf(user, TOOLS.screenList.allowedGroups)}
          toolLink={TOOLS.screenEdit}
        />
        <CommonLayout
          exact
          path={`${ROUTES.screenEdit}/:mac`}
          component={ScreenEdit}
          hasAccess={isMemberOf(user, TOOLS.screenEdit.allowedGroups)}
          toolLink={TOOLS.screenEdit}
        />
        <CommonLayout
          exact
          path={`${ROUTES.inventoryEdit}/:id`}
          component={InventoryEdit}
          hasAccess={isMemberOf(user, TOOLS.inventoryEdit.allowedGroups)}
          toolLink={TOOLS.inventoryEdit}
        />
        <CommonLayout
          exact
          path={ROUTES.inventoryTemplates}
          component={InventoryTemplates}
          hasAccess={isMemberOf(user, TOOLS.inventoryTemplates.allowedGroups)}
          toolLink={TOOLS.inventoryTemplates}
        />
        <CommonLayout
          exact
          path={`${ROUTES.inventorySlotsEdit}/:id`}
          component={InventorySlotsEdit}
          hasAccess={isMemberOf(user, TOOLS.inventorySlotsEdit.allowedGroups)}
          toolLink={TOOLS.inventorySlotsEdit}
        />
        <CommonLayout
          exact
          path={ROUTES.inventoryReplace}
          component={InventoryReplace}
          hasAccess={isMemberOf(user, TOOLS.inventoryReplace.allowedGroups)}
          toolLink={TOOLS.inventoryReplace}
        />
        <CommonLayout
          exact
          fullWidth
          path={ROUTES.inventoryList}
          component={InventoryList}
          hasAccess={isMemberOf(user, TOOLS.inventoryList.allowedGroups)}
          toolLink={TOOLS.inventoryList}
        />
        <CommonLayout
          exact
          path={ROUTES.inventoryActivate}
          component={InventoryActivate}
          hasAccess={isMemberOf(user, TOOLS.inventoryActivate.allowedGroups)}
          toolLink={TOOLS.inventoryActivate}
        />
        <CommonLayout
          exact
          fullWidth
          path={`${ROUTES.inventoryMovieLogs}/:id`}
          component={InventoryMovieLogs}
          hasAccess={isMemberOf(user, TOOLS.inventoryMovieLogs.allowedGroups)}
          toolLink={TOOLS.inventoryMovieLogs}
        />
        <CommonLayout
          exact
          path={ROUTES.jiraMap}
          component={JiraMap}
          hasAccess={isMemberOf(user, TOOLS.jiraMap.allowedGroups)}
          toolLink={TOOLS.jiraMap}
        />
        <CommonLayout
          exact
          fullWidth
          path={ROUTES.tagList}
          component={TagList}
          hasAccess={isMemberOf(user, TOOLS.tagList.allowedGroups)}
          toolLink={TOOLS.tagList}
        />
        <CommonLayout
          exact
          path={`${ROUTES.tagEdit}/:id`}
          component={TagEdit}
          hasAccess={isMemberOf(user, TOOLS.tagEdit.allowedGroups)}
          toolLink={TOOLS.tagEdit}
        />
        <CommonLayout
          exact
          path={ROUTES.tagCreate}
          component={TagCreate}
          hasAccess={isMemberOf(user, TOOLS.tagCreate.allowedGroups)}
          toolLink={TOOLS.tagCreate}
        />
        <CommonLayout
          exact
          fullWidth
          path={ROUTES.tagTypeList}
          component={TagTypeList}
          hasAccess={isMemberOf(user, TOOLS.tagTypeList.allowedGroups)}
          toolLink={TOOLS.tagTypeList}
        />
        <CommonLayout
          exact
          path={`${ROUTES.tagTypeEdit}/:id`}
          component={TagTypeEdit}
          hasAccess={isMemberOf(user, TOOLS.tagTypeEdit.allowedGroups)}
          toolLink={TOOLS.tagTypeEdit}
        />
        <CommonLayout
          exact
          path={ROUTES.tagTypeCreate}
          component={TagTypeCreate}
          hasAccess={isMemberOf(user, TOOLS.tagTypeCreate.allowedGroups)}
          toolLink={TOOLS.tagTypeCreate}
        />
        <CommonLayout
          exact
          fullWidth
          path={ROUTES.loopJobs}
          component={LoopJobs}
          hasAccess={isMemberOf(user, TOOLS.loopJobs.allowedGroups)}
          toolLink={TOOLS.loopJobs}
        />
        <CommonLayout
          exact
          path={ROUTES.vpnClients}
          component={VpnClients}
          hasAccess={isMemberOf(user, TOOLS.vpnClients.allowedGroups)}
          toolLink={TOOLS.vpnClients}
        />
        <CommonLayout
          exact
          path={ROUTES.vpnClients}
          component={VpnClients}
          hasAccess={isMemberOf(user, TOOLS.vpnClients.allowedGroups)}
          toolLink={TOOLS.vpnClients}
        />
        <CommonLayout
          exact
          path={`${ROUTES.faqTools}/:tool`}
          component={FaqTools}
          hasAccess={isMemberOf(user, TOOLS.faqTools.allowedGroups)}
          toolLink={TOOLS.faqTools}
        />
        <CommonLayout
          exact
          fullWidth
          path={ROUTES.programmatic}
          component={Programmatic}
          hasAccess={isMemberOf(user, TOOLS.programmatic.allowedGroups)}
          toolLink={TOOLS.programmatic}
        />
        <CommonLayout
          exact
          fullWidth
          path={ROUTES.incidentProviderList}
          component={IncidentProviderList}
          hasAccess={isMemberOf(user, TOOLS.incidentProviderList.allowedGroups)}
          toolLink={TOOLS.incidentProviderList}
        />
        <CommonLayout
          exact
          path={ROUTES.incidentProviderCreate}
          component={IncidentProviderCreate}
          hasAccess={isMemberOf(
            user,
            TOOLS.incidentProviderCreate.allowedGroups,
          )}
          toolLink={TOOLS.incidentProviderCreate}
        />
        <CommonLayout
          exact
          path={`${ROUTES.incidentProviderEdit}/:id`}
          component={IncidentProviderEdit}
          hasAccess={isMemberOf(user, TOOLS.incidentProviderEdit.allowedGroups)}
          toolLink={TOOLS.incidentProviderEdit}
        />
        <CommonLayout
          exact
          fullWidth
          path={ROUTES.incidentTemplateList}
          component={IncidentTemplateList}
          hasAccess={isMemberOf(user, TOOLS.incidentTemplateList.allowedGroups)}
          toolLink={TOOLS.incidentTemplateList}
        />
        <CommonLayout
          exact
          fullWidth
          path={`${ROUTES.incidentTemplateEdit}/:id`}
          component={IncidentTemplateEdit}
          hasAccess={isMemberOf(user, TOOLS.incidentTemplateEdit.allowedGroups)}
          toolLink={TOOLS.incidentTemplateEdit}
        />
        <CommonLayout
          exact
          fullWidth
          path={ROUTES.incidentTemplateCreate}
          component={IncidentTemplateCreate}
          hasAccess={isMemberOf(
            user,
            TOOLS.incidentTemplateCreate.allowedGroups,
          )}
          toolLink={TOOLS.incidentTemplateCreate}
        />
        <CommonLayout
          exact
          fullWidth
          path={ROUTES.program}
          component={Program}
          hasAccess={isMemberOf(user, TOOLS.program.allowedGroups)}
          toolLink={TOOLS.program}
        />
        <CommonLayout
          exact
          path={ROUTES.dbDump}
          component={DbDump}
          hasAccess={isMemberOf(user, TOOLS.dbDump.allowedGroups)}
          toolLink={TOOLS.dbDump}
        />
        <CommonLayout
          exact
          path={ROUTES.forbidden}
          component={Forbidden}
          hasAccess
        />
        <CommonLayout hasAccess header="Page not found" component={NotFound} />
      </Switch>
    </Suspense>
  );
}

Routes.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
    groups: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default memo(Routes);
