import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { styled } from '@mui/material';

import Navbar from 'components/common/Navbar';
import PrivateRoute from 'components/common/PrivateRoute';
import { ROUTES } from 'constants/routes';
import ErrorBoundary from 'components/common/ErrorBoundary';

const Content = styled('div')(({ theme }) => ({
  paddingTop: `${theme.navbarHeight + 10}px`,
  height: `calc(100% - ${theme.navbarHeight + 10}px)`,
}));

/**
 * Common layout for page with Navbar header
 * @param {string|node} header - title (text or component)
 * @param {boolean} hasAccess - if user has access
 * @param {React.Component} Component - component to display
 * @param {Object} location - current react router location
 * @param {string} toolLink - tool data
 * @param {boolean} fullWidth - fullwidth mode
 * @param {Object} restProps
 * @returns {jsx}
 */
function CommonLayout({
  header,
  hasAccess,
  component: Component,
  location,
  toolLink,
  fullWidth,
  ...restProps
}) {
  const Viewport = fullWidth ? Box : Container;

  return (
    <PrivateRoute
      hasAccess={hasAccess}
      redirectTo={ROUTES.forbidden}
      location={location}
      {...restProps}
    >
      <Viewport
        maxWidth={fullWidth ? '100vw' : 'md'}
        style={{ height: '100%' }}
      >
        <Box
          height="100%"
          position="relative"
          width={fullWidth ? '100vw' : '100%'}
          left="0px"
        >
          <Navbar toolLink={toolLink} />
          <ErrorBoundary>
            <Content>
              <Component />
            </Content>
          </ErrorBoundary>
        </Box>
      </Viewport>
    </PrivateRoute>
  );
}

CommonLayout.propTypes = {
  toolLink: PropTypes.shape({
    to: PropTypes.string,
    icon: PropTypes.node,
    text: PropTypes.string,
  }),
  hasAccess: PropTypes.bool,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.node,
  ]).isRequired,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  fullWidth: PropTypes.bool,
};

CommonLayout.defaultProps = {
  header: '',
  fullWidth: false,
};

export default memo(CommonLayout);
