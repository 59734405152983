import React, { memo, useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';

/**
 * Back button is shown only on Apple devices in PWA mode
 * @returns {JSX.Element|null}
 * @constructor
 */
function BackButton() {
  const [isStandalone, setIsStandalone] = useState(false);

  useEffect(() => {
    const checkStandaloneMode = () => {
      const isStandaloneMode = window.navigator.standalone;
      setIsStandalone(isStandaloneMode);
    };

    checkStandaloneMode();
    window.addEventListener('resize', checkStandaloneMode);

    return () => {
      window.removeEventListener('resize', checkStandaloneMode);
    };
  }, []);

  const handleBackClick = () => {
    if (window.opener) {
      window.opener.focus();
      window.close();
    } else {
      window.history.back();
    }
  };

  if (!isStandalone) return null;

  return (
    <IconButton size="small" onClick={handleBackClick}>
      <ArrowBackIcon />
    </IconButton>
  );
}

export default memo(BackButton);
