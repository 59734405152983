import { getAbsoluteUrl } from 'utils/url';

export const FIELD_FORCE_GROUP = 'sso_field_force';
export const SUPPORT_GROUP = 'sso_support';
export const SALES_GROUP = 'sso_sales';
export const SALES_COORDINATE_GROUP = 'sso_sales_coord';
export const SALES_ADMIN_GROUP = 'sso_sales_admin';
export const DEV_GROUP = 'sso_dev';
export const PUBLIC_GROUP = 'sso_public';
export const PARAMETRIC_GROUP = 'sso_parametric';
export const PROJECTS_GROUP = 'sso_projects';

// logouts from microsoft account
export const LOGOUT_URL = getAbsoluteUrl('/_sso/logout');
