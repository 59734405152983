import { APP_KEY, SSO_URL } from 'common/config';
import { storage } from 'utils/storage';

export function logoutUser() {
  storage.setJwt('');
  window.location = 'https://login.microsoftonline.com/common/oauth2/logout';
}

export function isMemberOf(user, targetGroups) {
  const { groups = [] } = user || {};
  if (!groups?.length) {
    return false;
  }
  return targetGroups.some(targetGroup => groups.includes(targetGroup));
}

export function toLoginPage() {
  window.location = `${SSO_URL}/auth/login/${APP_KEY}`;
}
