import React from 'react';
import { RecoilRoot } from 'recoil';
import { createRoot } from 'react-dom/client';

import 'index.scss';
import App from 'components/app/App';
import Providers from 'components/app/Providers';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <RecoilRoot>
    <Providers>
      <App />
    </Providers>
  </RecoilRoot>,
);
