import React, { memo, useCallback } from 'react';
import { useRecoilState } from 'recoil';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

import { ConfirmAtom } from 'atoms/confirm';

const CLICK_BACKDROP_REASON = 'backdropClick';

/**
 * shows Confirm dialog
 */
function ConfirmDialog() {
  const [confirm, setConfirm] = useRecoilState(ConfirmAtom);

  const cancel = useCallback(
    (_, reason) => {
      if (reason === CLICK_BACKDROP_REASON || !confirm?.reject) return;
      confirm.reject();
      setConfirm(null);
    },
    [setConfirm, confirm],
  );

  const approve = useCallback(() => {
    if (confirm?.resolve) {
      confirm.resolve();
      setConfirm(null);
    }
  }, [setConfirm, confirm]);

  if (!confirm) {
    return null;
  }

  return (
    <Dialog
      slotProps={{
        backdrop: { style: { backgroundColor: 'rgba(0,0,0,.5)' } },
      }}
      open
      onClose={cancel}
      maxWidth="md"
    >
      <DialogTitle>{confirm.title}</DialogTitle>
      <DialogContent>
        <Box whiteSpace="pre" minWidth={240} mb="12px">
          {confirm.message}
        </Box>
        {confirm.warning && <Alert severity="warning">{confirm.warning}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel} variant="outlined">
          {confirm.cancel}
        </Button>
        <Button onClick={approve} variant="contained" autoFocus>
          {confirm.ok}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(ConfirmDialog);
