import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useRecoilState } from 'recoil';

import { SnackbarAtom } from 'atoms/snackbar';

import './index.scss';

const SNACKBAR_POSITION = {
  vertical: 'top',
  horizontal: 'center',
};

/**
 * shows Snackbar which is set to SnackbarAtom
 */
function SnackbarManager({ autoHideDuration }) {
  const [snackbar, setSnackbar] = useRecoilState(SnackbarAtom);
  const handleClose = useCallback(
    (_, reason) => reason !== 'clickaway' && setSnackbar(null),
    [setSnackbar],
  );

  if (!snackbar) {
    return null;
  }

  const duration = {}.hasOwnProperty.call(snackbar, 'duration')
    ? snackbar.duration
    : autoHideDuration;

  return (
    <Snackbar
      open
      autoHideDuration={duration}
      onClose={handleClose}
      anchorOrigin={SNACKBAR_POSITION}
      className="snackbar"
    >
      <Alert severity={snackbar.severity || 'info'} onClose={handleClose}>
        {snackbar.message || ''}
      </Alert>
    </Snackbar>
  );
}

SnackbarManager.propTypes = {
  autoHideDuration: PropTypes.number,
};

SnackbarManager.defaultProps = {
  autoHideDuration: 5000,
};

export default memo(SnackbarManager);
