import React, { memo, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';

import Routes from 'components/app/Routes';
import { UserAtom } from 'atoms/user';
import { getCurrentUser } from 'requests/user';
import { EMPTY_OBJECT } from 'constants/common';
import { useNotification } from 'hooks/useNotifications';
import DotsLoader from 'components/common/DotsLoader';
import { stripJwtFromUrl } from 'utils/url';
import { storage } from 'utils/storage';

function App() {
  const { errorNotification } = useNotification();
  const [user, setUser] = useRecoilState(UserAtom);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const jwtFromUrl = stripJwtFromUrl();
    if (jwtFromUrl) {
      storage.setJwt(jwtFromUrl);
    }
    getCurrentUser()
      .then(setUser)
      .catch(error => {
        if (error.status === 401) {
          setUser(EMPTY_OBJECT);
          return;
        }
        errorNotification('Server error.');
      })
      .then(() => setLoaded(true));
  }, [errorNotification, setUser, setLoaded]);

  return user ? (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <BrowserRouter>
        <Routes user={user} />
      </BrowserRouter>
    </LocalizationProvider>
  ) : (
    !loaded && <DotsLoader backdrop />
  );
}

export default memo(App);
