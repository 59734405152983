import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

/**
 * Private route for pages
 * if user has no access, redirects to given redirect url
 * @param {Boolean} hasAccess
 * @param {String} redirectTo
 * @param {React.ReactNode} children
 * @param {Object} restProps
 * @returns {JSX.Element}
 */
function PrivateRoute({ hasAccess, redirectTo, children, ...restProps }) {
  return hasAccess ? (
    <Route render={() => children} {...restProps} />
  ) : (
    <Redirect to={redirectTo} />
  );
}

PrivateRoute.propTypes = {
  hasAccess: PropTypes.bool.isRequired,
  redirectTo: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default PrivateRoute;
