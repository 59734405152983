import axios from 'axios';
import queryString from 'query-string';

import { API_URL } from 'common/config';
import { DEFAULT_SERVER_ERROR } from 'constants/errors';
import { storage } from 'utils/storage';
import { toLoginPage } from 'utils/user';
import { refreshAccessToken } from 'requests/auth';

/**
 * Processes expired token
 * tries to refresh access token
 * when accessToken received - retries original request
 * @return Promise
 */
function processExpiredToken(requestConfig) {
  // eslint-disable-next-line no-param-reassign
  requestConfig.isRetry = true;
  return refreshAccessToken()
    .then(({ accessToken }) => {
      storage.setJwt(accessToken);
      // eslint-disable-next-line no-param-reassign
      requestConfig.headers.authorization = `Bearer ${accessToken}`;
    })
    .then(() => requestInstance(requestConfig));
}

/**
 * basic axios configuration
 * @return { Object } axios instance
 */
const requestInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
  paramsSerializer: params =>
    queryString.stringify(params, {
      arrayFormat: 'bracket',
    }),
});

function errorHandler(errorData) {
  return new Promise((resolve, reject) => {
    const { response = {}, config } = errorData;
    const { status = null } = response;
    // expired token
    if (status === 498 && !config.isRetry) {
      processExpiredToken(config).then(resolve).catch(reject);
      return;
    }
    if (status === 401) {
      toLoginPage();
      resolve();
      return;
    }
    const message = response.data?.error || DEFAULT_SERVER_ERROR;
    const errorObject = { status, message };
    reject(errorObject);
  });
}

/**
 * wraps request in a promise which on fall resolves with given fallback value
 * logs error to console
 * @returns {Promise<unknown>}
 */
export function withSilentError(requestCall, fallbackValue = {}) {
  return new Promise(resolve => {
    requestCall
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        console.error(error);
        resolve(fallbackValue);
      });
  });
}

/**
 * wraps request in a promise which on fall resolves with given fallback value
 * logs error to console
 * @returns {Promise<unknown>}
 */
export function withNotification(requestCall, fallbackValue = {}) {
  return new Promise(resolve => {
    requestCall
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        console.error(error);
        resolve(fallbackValue);
      });
  });
}

/**
 * adds jwt to each request
 */
function authInterceptor(config) {
  const jwt = storage.getJwt();
  // eslint-disable-next-line no-param-reassign
  config.headers.authorization = `Bearer ${jwt}`;
  return config;
}
requestInstance.interceptors.request.use(authInterceptor);

requestInstance.interceptors.response.use(({ data }) => data, errorHandler);

export default requestInstance;
