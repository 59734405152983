export const PLAYERS_URL = 'players';
export const INCIDENT_TEMPLATES_URL = 'incident-templates';
export const INCIDENT_PROVIDERS_URL = 'incident-providers';
export const ZONES_URL = 'zones';
export const FEED_TEMPLATES_URL = 'feed-templates';
export const ZONE_FEED_TEMPLATES_URL = 'zone-feed-templates';
export const ZONE_DETAILS_URL = 'zone-details';
export const REGIONS_URL = 'regions';
export const PLAYER_TEMPLATES_URL = 'player-templates';
export const PLAYER_TYPES_URL = 'player-types';
export const MEDIA_CONTENT_TYPES_URL = 'media-content-types';
export const PLAYER_ENVIRONMENTS_URL = 'player-environments';
export const PLAYER_STREAM_TYPES_URL = 'player-stream-types';
export const PLAYER_VEHICLE_PLATFORM_TYPES_URL =
  'player-vehicle-platform-types';
export const PLAYER_LOOPS_URL = 'player-loops';
export const PLAYER_JSON_QUERY_URL = 'player-json-query';
export const VALIDATE_URL = 'validate';
export const BEACONS_URL = 'beacons';
export const JIRA_URL = 'jira';
export const VPN_URL = 'vpn';
export const USER_URL = 'users';
export const PRODUCTS_URL = 'products';
export const SCREENS_URL = 'screens';
export const SCREEN_VIEWS_URL = 'screen-views';
export const INVENTORIES_URL = 'inventories';
export const IMPRESSIONS_URL = 'impressions';
export const INVENTORY_TEMPLATES_URL = 'inventory-templates';
export const TAGS_URL = 'tags';
export const TAG_TYPES_URL = 'tag-types';
export const ZONE_TAGS_URL = 'zone-tags';
export const ZONE_PICS_URL = 'zone-pics';
export const LOOP_JOBS_URL = 'loop-jobs';
export const MEDIAS_URL = 'medias';
export const PROGRAMMATIC_URL = 'programmatic';
export const PROGRAM_URL = 'program';
export const DB_DUMP_URL = 'db-dump';
