export function triggerDownloadViaLink(url, filename) {
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

/**
 * sets color-theme class on document root
 */
export function setColorTheme(mode = 'dark') {
  const action = mode === 'dark' ? 'remove' : 'add';
  document.body.classList[action]('theme-light');
}
